import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'

import { rhythm } from '../utils/typography'

import { LinkButton } from './button'
import Cover from './cover'

const Newsletter = () => {
  const data = useStaticQuery(graphql`
    query Logo {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 200, height: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          },
        }
      }
    }
  `)

  return (
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 0 auto ${rhythm(1)} auto;
      `}
    >
      <Cover fixedImage={data.logo.childImageSharp.fixed} />
      <div
        css={css`
          font-size: 1.1rem;
          padding: 0 ${rhythm(1)};
          display: flex;
          flex-direction: column;
          text-align: center;
          max-width: ${rhythm(13)};
        `}
      >
        <p><strong>Subscribe to the newsletter and get notifications about new posts.</strong></p>
        <LinkButton
          href="/newsletter"
          css={css`
            font-weight: bold;
            text-transform: uppercase;
          `}
        >
          Subscribe
        </LinkButton>
      </div>
    </div>
  )
}

export default Newsletter
