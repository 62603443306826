import React from 'react'
import { css } from '@emotion/core'
import Image from 'gatsby-image'

const Cover = ({ fixedImage }) => (
  <div className="cover">
    <Image
      fixed={fixedImage}
      alt="Cover image"
      css={css`
        padding: 1rem;
        border: 0.15rem solid rgba(0,0,0,0.1w5);
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
        img {
          margin-bottom: 0;
        }
      `}
    />
  </div>
)

export default Cover
