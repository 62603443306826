import Typography from 'typography'
import Wordpress2016 from 'typography-theme-wordpress-2016'

Wordpress2016.overrideThemeStyles = () => ({
  'a.gatsby-resp-image-link': {
    boxShadow: `none`,
  },
})

delete Wordpress2016.googleFonts
delete Wordpress2016.headerFontFamily
delete Wordpress2016.bodyFontFamily

const typography = new Typography({
  ...Wordpress2016,
  baseFontSize: '18px',
  baseLineHeight: 1.7,
  headerFontFamily: ['Inter', 'Arial', 'sans-serif'],
  bodyFontFamily: ['Inter', 'Arial', 'sans-serif'],
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
