import { rhythm } from './typography'

export const PageWidths = {
  NORMAL: rhythm(24),
  WIDE: rhythm(36),
}

export const Colors = {
  MAIN: `#d53b30`,
  TEXT: `#333333`,
  BACKGROUND: `#ffffff`,
  ORANGE: `#FFAC31`,
}

export const Fonts = {
  SANS: `Inter, Arial, sans-serif`,
}
