import React from 'react'

function Timeline({ source, zoom, height }) {
  const zoomProp = zoom || 0;
  const heightProp = height || 650;

  return (
      <div className='timeline'>
        <iframe src={`https://cdn.knightlab.com/libs/timeline3/latest/embed/index.html?source=${source}&font=Default&lang=en&initial_zoom=${zoomProp}&timenav_position=top&height=${heightProp}`} width='100%' height={heightProp} webkitallowfullscreen={`true`} mozallowfullscreen={`true`} allowFullScreen frameBorder='0'></iframe>
      </div>
  );
}

export default Timeline;
