import React from 'react'

import { Colors, Fonts } from '../utils/layoutUtils'

const style = {
  fontFamily: Fonts.SANS,
  border: `none`,
  backgroundColor: Colors.MAIN,
  color: `#fff`,
  padding: `15px`,
  margin: `15px`,
  borderRadius: `2px`,
  cursor: `pointer`,
}

export function Button({
  children,
  style: additionalStyle,
  ...props
}) {
  return (
    <button
      type="button"
      style={{
        ...style,
        ...additionalStyle,
      }}
      {...props}
    >
      {children}
    </button>
  )
}

export function LinkButton({
  children,
  style: additionalStyle,
  ...props
}) {
  return (
    <a
      style={{
        ...style,
        ...additionalStyle,
      }}
      {...props}
    >
      {children}
    </a>
  )
}

